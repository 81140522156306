class JobDetailFloatingMenu {
  constructor(showThreshold) {
    this.isFloatingMenuHidden = false;
    this.showThreshold = showThreshold;
    this.bindEvents();
    this.scrollEvent();
  }

  scrollEvent() {
    $(window).on('scroll', () => {
      if (this.isSlideFormVisible()) {
        $('.c-floating-menu__js, .js-back-to-top').fadeOut(100);
      } else if (
        $(window).scrollTop() >= this.showThreshold &&
        !this.isFloatingMenuHidden
      ) {
        $('.c-floating-menu__js, .js-back-to-top').fadeIn(100);
      } else {
        $('.c-floating-menu__js, .js-back-to-top').fadeOut(100);
      }
    });
  }

  bindEvents() {
    // scroll back to top
    $('.js-back-to-top').on('click', () => {
      $('body, html').animate({ scrollTop: 0 }, 500);
    });
    // close button on floating menu
    $('.c-floating-menu__apply .btn-close').on('click', () => {
      $('.c-floating-menu__js').hide();
      this.isFloatingMenuHidden = true;
    });
  }

  // `#horizontal_slide_form_wrapper`要素が見える間trueを返す
  isSlideFormVisible() {
    const slideForm = document.querySelector('#horizontal_slide_form_wrapper');
    if (!slideForm) return false;

    const rect = slideForm.getBoundingClientRect();
    const slideFormTop = rect.top;
    const slideFormBottom = rect.bottom;

    return slideFormBottom > 0 && slideFormTop <= window.innerHeight;
  }
}

export default JobDetailFloatingMenu;
